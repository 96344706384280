import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
} from "@mui/material";
import { CloudCenterOrder } from "@queue/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HisComments } from "./HisComments";
import { HisOrderItems } from "./HisOrderItems";
import { HisTimestamp } from "./HisTimestamp";

interface Props {
  selectedOrder: any;
  setSelectedOrder: (value: CloudCenterOrder | null) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

/* TabPanel = div with hidden attribute  */
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const OrderDetailsDialog: React.FunctionComponent<Props> = ({
  selectedOrder,
  setSelectedOrder,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [value, setValue] = useState(0);

  /* Handlers */
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={true}
      onClose={() => setSelectedOrder(null)}
      fullWidth
    >
      <AppBar position="static" color="primary">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
        >
          <Tab label={t("orderTable.time")} />
          <Tab label={t("orderTable.item")} />
          <Tab label={t("orderTable.comment")} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <HisTimestamp order={selectedOrder} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <HisOrderItems order={selectedOrder} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        {!!selectedOrder["CloudCenterOrders.id"] && (
          <HisComments order={selectedOrder} />
        )}
      </TabPanel>
      <DialogActions>
        <Button
          onClick={() => setSelectedOrder(null)}
          color="secondary"
          variant="contained"
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
