import React from "react";
import {
  Typography,
  Card,
  CardActions,
  Button,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export const UserNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    /* Outer Flex */
    <Container maxWidth="md">
      <Card>
        <Typography
          variant="h5"
          align="center"
          style={{
            fontWeight: "bolder",
            padding: 10,
          }}
        >
          {t("userNotFound")}
        </Typography>
        <CardActions>
          <Button onClick={() => signOut(auth)}>Change user</Button>
        </CardActions>
      </Card>
    </Container>
  );
};
