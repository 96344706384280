import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { accountSlice } from "./accountSlice";
import { chartSlice } from "./chart";
import { filterSlice } from "./filterSlice";
import { ordersSlice } from "./orderSlice";

const reducer = {
  account: accountSlice.reducer,
  hisFilter: filterSlice.reducer,
  orders: ordersSlice.reducer,
  chart: chartSlice.reducer,
};

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
