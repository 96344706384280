import React from "react";
import { OrderSourceType } from "@queue/types";
import uber from "../../images/uberround.png";
import foodpanda from "../../images/panda.png";
import queue from "../../images/queuelogo.png";
import { useAppSelector } from "../../hooks/reduxHooks";

interface Props {
  platform: OrderSourceType;
  width: number;
}

export const LogoImage: React.FC<Props> = ({ platform, width }) => {
  const logoUrl = useAppSelector((state) => state.account.cloudCenterLogoURL);
  if (platform === OrderSourceType.DELIVERY_HERO) {
    return (
      <img
        src={foodpanda}
        alt="Foodpanda"
        width={width}
        style={{ alignSelf: "center" }}
      />
    );
  } else if (platform === OrderSourceType.UBER) {
    return (
      <img
        alt="Uber Eats"
        src={uber}
        width={width}
        style={{ alignSelf: "center" }}
      />
    );
  } else if (
    platform === OrderSourceType.Custom ||
    platform === OrderSourceType.CloudPOS ||
    platform === OrderSourceType.POS
  ) {
    return (
      <img
        alt="POS Platform"
        src={queue}
        width={width}
        style={{ alignSelf: "center" }}
      />
    );
  } else {
    return <div />;
  }
};
