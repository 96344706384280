import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import { BiFace } from "react-icons/bi";
import { BiBrightnessHalf } from "react-icons/bi";

import FlexView from "react-flexview/lib";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { accountSlice } from "../../redux/accountSlice";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

export const Navigation: React.FunctionComponent = () => {
  /* Hooks and Const */
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const routes = ["/dashboard", "/completedOrders", "/pickup", "/packaging"];

  /* States */
  const [selectedTab, setSelectedTab] = useState(0);

  /* Handler */
  const handleChange = (
    _event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setSelectedTab(0);
        break;
      case "/completedOrders":
        setSelectedTab(1);
        break;
      case "/pickup":
        setSelectedTab(2);
        break;
      case "/packaging":
        setSelectedTab(3);
        break;
    }
  }, [location.pathname]);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleToggleDisplay = () => {
    dispatch(accountSlice.actions.toggleDarkmode());
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <FlexView style={{ flex: 1, marginLeft: 10 }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
          >
            <Tab
              label={t("navigation.dashboard")}
              component={Link}
              to={routes[0]}
            />
            <Tab
              label={t("navigation.completed")}
              component={Link}
              to={routes[1]}
            />
            <Tab
              label={t("navigation.pickup")}
              component={Link}
              to={routes[2]}
            />
            <Tab
              label={t("navigation.package")}
              component={Link}
              to={routes[3]}
            />
          </Tabs>
        </FlexView>

        <FlexView>
          <IconButton onClick={handleToggleDisplay}>
            <BiBrightnessHalf color={"white"} />
          </IconButton>

          <ToggleButtonGroup
            exclusive
            size="small"
            value={i18n.language}
            onChange={(_, target) => {
              i18n.changeLanguage(target);
            }}
          >
            <ToggleButton value="zh-TW">中文</ToggleButton>
            <ToggleButton value="en">English</ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            color={"inherit"}
            onClick={() => {
              navigate("/");
              signOut(auth);
            }}
          >
            <BiFace />
          </IconButton>
        </FlexView>
      </Toolbar>
    </AppBar>
  );
};
