import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppQuery } from "../../../hooks/cube";

interface Props {
  order: any;
}

export const HisOrderItems: React.FunctionComponent<Props> = ({ order }) => {
  /* Hooks */
  const { t } = useTranslation();

  const { resultSet } = useAppQuery({
    dimensions: [
      "LineItems.id",
      "LineItems.productName",
      "LineItems.quantity",
      "LineItems.variantName",
      "LineItems.amount",
    ],
    filters: [
      {
        member: "CloudCenterOrders.id",
        operator: "equals",
        values: [order["CloudCenterOrders.id"]],
      },
    ],
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("orderTable.item")}</TableCell>
          <TableCell align="right">{t("orderTable.amount")}</TableCell>
          <TableCell align="right">{t("orderTable.price")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {resultSet?.tablePivot()?.map((cartItem: any) => (
          <TableRow key={cartItem["LineItems.id"]}>
            <TableCell>{`${cartItem["LineItems.productName"]} ${cartItem["LineItems.variantName"] || ""}`}</TableCell>
            <TableCell align="right">
              {cartItem["LineItems.quantity"]}
            </TableCell>
            <TableCell align="right">{cartItem["LineItems.amount"]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
