import React from "react";
import FlexView from "react-flexview";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { CancelledTodayCard } from "./cards/CancelledToday";
import { KitchenReadyCard } from "./cards/KitchenReady";
import { OngoingCard } from "./cards/Ongoing";
import { OrdersTodayCard } from "./cards/OrdersToday";
import { PendingCard } from "./cards/Pending";
import { PickupReadyCard } from "./cards/PickupReady";
import { WebOrdersCard } from "./cards/WebOrders";

export const Kpis: React.FunctionComponent = () => {
  /* Hooks */

  const webEnabled = useAppSelector((state) => state.account.webEnabled);

  return (
    <div
      style={{
        marginTop: 25,
        marginBottom: 5,
        flex: 1,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <FlexView
        style={{ flex: 3 }}
        wrap
        vAlignContent="center"
        hAlignContent="center"
      >
        {/* Delicvery orders */}
        {webEnabled && <WebOrdersCard />}

        {/* Pending CARD */}
        <PendingCard />

        {/* Ongoing Card */}
        <OngoingCard />

        {/* KITCHEN READY CARD */}
        <KitchenReadyCard />

        {/* PICK UP READY CARD */}
        <PickupReadyCard />

        {/* ORDER TODAY CARD */}
        <OrdersTodayCard />

        {/* Cancelled CARD */}
        <CancelledTodayCard />
      </FlexView>
    </div>
  );
};
