import React, { useEffect, useState } from "react";
import { Card, Typography, Box, useTheme, Stack } from "@mui/material";
import FlexView from "react-flexview/lib";
import { OrderCard } from "./OrderCard";
import { useTranslation } from "react-i18next";
import useDimensions from "react-cool-dimensions";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useAllPreparingOrders } from "../../hooks/db";
import { PoweredByQUEUE } from "../navigation/PoweredByQUEUE";

export const PreparingBoard: React.FC = () => {
  /* Hooks */
  const { t } = useTranslation();
  const theme = useTheme();

  /* Selectors */

  const kitchenOrders = useAllPreparingOrders();

  /* Calculation Optimal CardSize */

  const { observe, width } = useDimensions();
  const [optimalCardSize, setOptimalCardSize] = useState(0);
  const [minimumCardSize] = useState(280);

  useEffect(() => {
    const currentMargin = 5;
    const amountOfCards = Math.floor(width / minimumCardSize);

    setOptimalCardSize(width / amountOfCards - currentMargin);
  }, [width, minimumCardSize]);

  const handleFullScreen = useFullScreenHandle();

  /* Full Scren Handler */
  const fullScreenToggler = () => {
    if (handleFullScreen.active) {
      handleFullScreen.exit();
    } else {
      handleFullScreen.enter();
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FullScreen handle={handleFullScreen}>
        <Box
          sx={{
            minHeight: "100%",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Card ref={observe} onClick={fullScreenToggler}>
            <Stack direction="row" justifyContent="space-between">
              <Stack
                direction="row"
                sx={{ flexGrow: 1, justifyContent: "center" }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  style={{ fontWeight: "bolder" }}
                >
                  {t("PreparingYourOrder")}
                </Typography>
              </Stack>
              {handleFullScreen.active && <PoweredByQUEUE />}
            </Stack>
          </Card>
          <FlexView
            wrap
            style={{
              marginTop: 10,
              marginLeft: -2,
            }}
          >
            {kitchenOrders.data?.map((order) => (
              <OrderCard
                //@ts-ignore
                order={order}
                cardWidth={optimalCardSize}
                key={order.id}
              />
            ))}
          </FlexView>
        </Box>
      </FullScreen>
    </div>
  );
};
