import React from "react";
import { KpiCard } from "../KpiCard";
import { BiSync } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { usePreparingOrders } from "../../../../hooks/db";

export const OngoingCard: React.FC = () => {
  const orders = usePreparingOrders();
  const { t } = useTranslation();

  return (
    <KpiCard
      icon={
        <BiSync
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
      colour="#69000C"
      count={orders?.data?.length || 0}
      label={t("kpi.ongoing")}
      disabled={false}
      //@ts-ignore
      orderList={orders?.data}
    />
  );
};
