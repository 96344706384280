import React from "react";

import { KpiCard } from "../KpiCard";
import { BiUndo } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useCancelledOrders } from "../../../../hooks/db";

export const CancelledTodayCard: React.FC = () => {
  const orders = useCancelledOrders();
  const { t } = useTranslation();

  return (
    <KpiCard
      label={t("kpi.cancelled")}
      count={orders?.data?.length || 0}
      colour="#BB0A21"
      disabled={false}
      //@ts-ignore
      orderList={orders?.data}
      /* B82601 */
      icon={
        <BiUndo
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
    />
  );
};
