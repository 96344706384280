import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"

// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/database";
// import "firebase/functions";

export const firebaseConfig = {
  apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
  authDomain: "queue-api.firebaseapp.com",
  databaseURL: "https://queue-api.firebaseio.com",
  projectId: "queue-api",
  storageBucket: "queue-api.appspot.com",
  messagingSenderId: "1063204016925",
  appId: "1:1063204016925:web:f1ff415d08cb4c068487f4",
  measurementId: "G-7TM0C59EHN",
};


// if (process.env.NODE_ENV === "development") {
//   firebase.functions().useEmulator("localhost", 5001);
//   // firebase.auth().useEmulator("http://localhost:9099?ns=queue-api-dev");
//   // firebase.database().useEmulator("localhost", 8080);
// }

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth()

export const firestore = getFirestore()

export const functions = getFunctions()

// if (process.env.NODE_ENV === "development") {
//   connectFunctionsEmulator(functions, 'localhost', 5001)
// }
