import { Typography } from "@mui/material";
import React from "react";
import FlexView from "react-flexview/lib";
import queuelogo from "../../images/queuelogo.png";
import { useTranslation } from "react-i18next";
import { FullScreenHandle } from "react-full-screen";
import { PoweredByQUEUE } from "../navigation/PoweredByQUEUE";

// interface CloudcenterData {
//   logoURL: string;
//   name: string;
// }

interface Props {
  logoUrl?: string | undefined;
  handleFullScreen: FullScreenHandle;
}

export const TopPage: React.FunctionComponent<Props> = ({
  logoUrl,
  handleFullScreen,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* Selectors */
  // const serviceCenterId = useSelector(selectCloudCenterId);

  /* Firestore */
  // const cloudcenterRef = firebase
  //   .firestore()
  //   .collection("cloudCenters")
  //   .doc(serviceCenterId);

  // const [cloudcenterData] = useDocumentData<CloudcenterData>(cloudcenterRef);

  /* Full Scren Handler */
  const fullScreenToggler = () => {
    if (handleFullScreen.active) {
      handleFullScreen.exit();
    } else {
      handleFullScreen.enter();
    }
  };

  return (
    <div
      style={{
        paddingTop: 5,
      }}
      onClick={fullScreenToggler}
    >
      <FlexView>
        <div style={{ flex: 1 }}>
          {logoUrl && (
            <img
              style={{
                width: 150,
                paddingLeft: 15,
                paddingTop: 5,
              }}
              src={logoUrl}
              alt="customerlogo"
            />
          )}
        </div>
        <FlexView
          hAlignContent="center"
          vAlignContent="center"
          style={{ flex: 2 }}
        >
          <Typography variant="h1" style={{ fontWeight: "bolder" }}>
            {t("PickUpPoint")}
          </Typography>
        </FlexView>

        <FlexView
          hAlignContent="right"
          vAlignContent="center"
          style={{ flex: 1 }}
        >
          <PoweredByQUEUE />
        </FlexView>
      </FlexView>
    </div>
  );
};
