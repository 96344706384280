import React from "react";
import { TotalSales } from "./charts/TotalSales";
import { SalesArea } from "./charts/SalesArea";
import { BrandSales } from "./charts/BrandSales";
import { Stack, Card, CardHeader, CardContent } from "@mui/material";
import { BrandAreaSales } from "./charts/BrandAreaSales";
import { useTranslation } from "react-i18next";

export const ChartView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
        <Card sx={{ flex: 1 }}>
          <CardHeader title={t("reporting.brandSales")} />
          <CardContent>
            <BrandSales />
          </CardContent>
        </Card>
        <Card sx={{ flex: 2 }}>
          <CardHeader title={t("reporting.salesOverTime")} />
          <CardContent>
            <BrandAreaSales />
          </CardContent>
        </Card>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Card sx={{ flex: 1 }}>
          <CardHeader title={t("reporting.platformSales")} />
          <CardContent>
            <TotalSales />
          </CardContent>
        </Card>
        <Card sx={{ flex: 2 }}>
          <CardHeader title={t("reporting.salesOverTime")} />
          <CardContent>
            <SalesArea />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
