import { TableCell, TableRow } from "@mui/material";
import { CloudCenterOrder } from "@queue/types";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  order: CloudCenterOrder;
}

export const DeliveryDetails: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  return (
    <>
      {order.customer?.address && (
        <TableRow>
          <TableCell>{order.customer?.address}</TableCell>
        </TableRow>
      )}
      {order.customer?.companyName && (
        <TableRow>
          <TableCell>{t("orderTable.companyName")}</TableCell>
          <TableCell colSpan={4}>{order.customer?.companyName}</TableCell>
        </TableRow>
      )}
      {order.customer?.deliveryNotes && (
        <TableRow>
          <TableCell>{t("orderTable.deliveryNotes")}</TableCell>
          <TableCell colSpan={4}>{order.customer?.deliveryNotes}</TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell colSpan={3}>{order.customer?.name}</TableCell>
        <TableCell colSpan={2}>{order.customer?.phone}</TableCell>
      </TableRow>
    </>
  );
};
