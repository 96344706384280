import React from "react";
import { ReadyPickup } from "./ReadyPickUp";
import { PreparingBoard } from "./PreparingBoard";
import { TopPage } from "./TopPage";
import { FullScreenHandle } from "react-full-screen";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useTheme, Box, Stack } from "@mui/material";

interface Props {
  handleFullScreen: FullScreenHandle;
}

export const PickUpDashboard: React.FC<Props> = ({ handleFullScreen }) => {
  const logoUrl = useAppSelector((state) => state.account.cloudCenterLogoURL);

  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <TopPage logoUrl={logoUrl} handleFullScreen={handleFullScreen} />

      <Stack direction="row" spacing={2}>
        <PreparingBoard />
        <ReadyPickup />
      </Stack>
    </Box>
  );
};
