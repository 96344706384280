import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface FilterStates {
  platform: string;
  orderType: string;
  orderStatus: string;
  selectedBrands: string[];
  date: string;
  fromDate: number;
  toDate: number;
}

dayjs.extend(utc);
dayjs.extend(timezone);
/* TODO: needs to be set from Cloud-Center-Store Settings */
dayjs.tz.setDefault("Asia/Taipei");

export const initialState = {
  platform: "allPlatforms",
  orderType: "allOrderType",
  orderStatus: "allStatus",
  selectedBrands: [],
  date: "today",
  fromDate: dayjs().tz().startOf("day").valueOf(),
  toDate: dayjs().tz().endOf("day").valueOf(),
} as FilterStates;

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setPlatformFilter: (state, { payload }) => {
      state.platform = payload;
    },
    setOrderTypeFilter: (state, { payload }) => {
      state.orderType = payload;
    },
    setOrderStatusFilter: (state, { payload }) => {
      state.orderStatus = payload;
    },
    setSelectedBrands: (state, { payload }) => {
      state.selectedBrands = payload;
    },
    setDate: (state, { payload }) => {
      state.date = payload;
      if (payload === "today") {
        state.fromDate = dayjs().tz().startOf("day").valueOf();
        state.toDate = dayjs().tz().endOf("day").valueOf();
      } else if (payload === "yesterday") {
        state.fromDate = dayjs()
          .tz()
          .startOf("day")
          .subtract(1, "day")
          .valueOf();
        state.toDate = dayjs().tz().endOf("day").subtract(1, "day").valueOf();
      } else if (payload === "thisWeek") {
        state.fromDate = dayjs().tz().startOf("week").valueOf();
        state.toDate = dayjs().tz().endOf("day").valueOf();
      } else if (payload === "lastWeek") {
        state.fromDate = dayjs()
          .tz()
          .startOf("week")
          .subtract(1, "week")
          .valueOf();
        state.toDate = dayjs().tz().endOf("week").subtract(1, "week").valueOf();
      } else if (payload === "thisMonth") {
        state.fromDate = dayjs().tz().startOf("month").valueOf();
        state.toDate = dayjs().tz().endOf("day").valueOf();
      } else if (payload === "lastMonth") {
        state.fromDate = dayjs()
          .subtract(1, "month")
          .startOf("month")
          .valueOf();

        state.toDate = dayjs()
          .tz()
          .subtract(1, "month")
          .endOf("month")
          .valueOf();
      }
    },
    setFromDate: (state, { payload }) => {
      state.fromDate = payload;
    },
    setToDate: (state, { payload }) => {
      state.toDate = payload;
    },
  },
});
