import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderTable } from "./OrderTable";
import { ChartView } from "./ChartView";
import { RangeSelector } from "./RangeSelector";
// import { BrandList } from "./BrandList";

export const CompletedOrders: React.FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)} centered>
        <Tab label={t("listView")} />
        <Tab label={t("chartView")} />
        {/* <Tab label={t("brandView")} /> */}
      </Tabs>
      <RangeSelector />
      {tab === 0 && <OrderTable />}
      {tab === 1 && <ChartView />}
      {/* {tab === 2 && <BrandList />} */}
    </Box>
  );
};
