import React from "react";
import { KpiCard } from "../KpiCard";
import { BiCalendarAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useTodayOrders } from "../../../../hooks/db";

export const OrdersTodayCard: React.FC = () => {
  const orders = useTodayOrders();
  const { t } = useTranslation();

  return (
    <KpiCard
      icon={
        <BiCalendarAlt
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
      colour="#144A5C"
      count={orders?.data?.length || 0}
      label={t("kpi.orderToday")}
      disabled={true}
    />
  );
};
