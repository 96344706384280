import {
  Typography,
  Dialog,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  DialogActions,
  useTheme,
} from "@mui/material";
import React from "react";
import FlexView from "react-flexview";
import { CloudCenterOrder } from "@queue/types";
import { LogoImage } from "../../brands/Logo";
import { OrderStatusIcon } from "../../brands/OrderTypeLogo";
import Timeago from "react-timeago";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/reduxHooks";
import dayjs from "dayjs";

interface Props {
  orderList?: CloudCenterOrder[];
  open: boolean;
  handleClose(): void;
  heading: string;
}

export const KpiDialog: React.FunctionComponent<Props> = ({
  orderList,
  open,
  handleClose,
  heading,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const theme = useTheme();
  const storesMap = useAppSelector((state) => state.account.stores || {});
  const brandMap = useAppSelector((state) => state.account.brands || {});

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper>
        <FlexView column>
          <FlexView
            hAlignContent="center"
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <Typography style={{ padding: 5 }} variant="h6" color="white">
              {heading}
            </Typography>
          </FlexView>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("orderTable.platform")}</TableCell>
                <TableCell align="center">{t("orderTable.type")}</TableCell>
                <TableCell align="center">{t("orderTable.order")}</TableCell>
                <TableCell align="center">{t("orderTable.brand")}</TableCell>
                <TableCell align="center">{t("orderTable.time")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orderList?.length === undefined ? (
                <TableRow>
                  <TableCell align="center" size="medium" colSpan={5}>
                    {t("noOrderDisplay")}
                  </TableCell>
                </TableRow>
              ) : (
                orderList?.map((order) => {
                  return (
                    <React.Fragment key={order.id}>
                      <TableRow hover>
                        <TableCell align="center">
                          <LogoImage platform={order.platform} width={25} />
                        </TableCell>
                        <TableCell align="center">
                          <OrderStatusIcon orderType={order.orderType} />
                        </TableCell>
                        <TableCell align="center">{order.name}</TableCell>
                        <TableCell align="center">{order.brandName}</TableCell>
                        <TableCell align="center">
                          <Timeago
                            date={dayjs(order.createdAt?.toDate()).toDate()}
                            formatter={(value, unit) => {
                              if (unit === "second" && value < 60)
                                return t("timeago.justNow");
                              if (unit === "minute")
                                return `${value} ` + t("timeago.minAgo");
                              if (unit === "hour")
                                return `${value} ` + t("timeago.hourAgo");
                              if (unit === "day")
                                return `${value} ` + t("timeago.dayAgo");
                              if (unit === "week")
                                return `${value} ` + t("timeago.weekAgo");
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      {order.pendingStoreIds?.map((storeBrand) => {
                        const storeName = storesMap[storeBrand.storeId];
                        // const brandName = brandMap[storeBrand.brandId];
                        return (
                          <TableRow
                            key={storeBrand.storeId }
                          >
                            <TableCell></TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  width: 20,
                                  alignSelf: "center",
                                  height: 20,
                                  borderRadius: 4,
                                  backgroundColor: "red",
                                }}
                              />
                            </TableCell>
                            <TableCell>{storeName}</TableCell>
                            <TableCell>{""}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}

                      {order.acceptedStoreIds?.map((storeBrand) => {
                        const storeName = storesMap[storeBrand.storeId];
                        // const brandName = brandMap[storeBrand.brandId];

                        return (
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  width: 20,
                                  alignSelf: "center",
                                  height: 20,
                                  borderRadius: 4,
                                  backgroundColor: "orange",
                                }}
                              />
                            </TableCell>
                            <TableCell>{storeName}</TableCell>
                            <TableCell>{""}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}
                      {order.readyStoreIds?.map((storeBrand) => {
                        const storeName = storesMap[storeBrand.storeId];
                        // const brandName = brandMap[storeBrand.brandId];

                        return (
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  width: 20,
                                  alignSelf: "center",
                                  height: 20,
                                  borderRadius: 4,
                                  backgroundColor: "green",
                                }}
                              />
                            </TableCell>
                            <TableCell>{storeName}</TableCell>
                            <TableCell>{""}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </Table>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              style={{ width: 100 }}
            >
              {t("close")}
            </Button>
          </DialogActions>
        </FlexView>
      </Paper>
    </Dialog>
  );
};
