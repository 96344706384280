import React from "react";
import { useAppQuery } from "../../../hooks/cube";
import { colors } from "./colors";
import {
  Bar,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import dayjs from "dayjs";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectDateRange } from "../../../selectors/filterSelector";

export const BrandAreaSales: React.FC = () => {
  const dateRange = useAppSelector(selectDateRange);

  const { resultSet } = useAppQuery({
    measures: ["Orders.totalAmount"],
    dimensions: ["BrandGroups.name", "BrandGroups.customColor"],
    timeDimensions: [
      {
        dimension: "CloudCenterOrders.createdAt",
        //@ts-ignore
        dateRange: dateRange,
        granularity: "day",
      },
    ],
    filters: [
      {
        operator: "set",
        member: "BrandGroups.name",
      },
    ],
  });

  const data = resultSet?.chartPivot().map((row) => {
    return { ...row, date: dayjs(row.x).format("MM/DD") };
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="9 9" />
        {resultSet?.seriesNames().map?.((s, index) => {
          return (
            <Bar
              key={s.key}
              dataKey={s.key}
              stackId="1"
              fill={s?.yValues?.[1] || colors[index]}
              name={s.title?.split(",")[0]}
            />
          );
        })}
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
};
