import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { CloudCenterOrder } from "@queue/types";
import React from "react";
import FlexView from "react-flexview/lib";
import { useTranslation } from "react-i18next";
import { BiHomeAlt } from "react-icons/bi";
import { useKitchenReadyOrders } from "../../hooks/db";
import { KitchenReadyRow } from "./KitchenReadyRow";

interface Props {
  setSelectedOrder: (value: CloudCenterOrder | null) => void;
}

export const KitchenReady: React.FC<Props> = ({ setSelectedOrder }) => {
  /* Hooks */
  const { t } = useTranslation();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  /* Firestore */
  const kitchenOrders = useKitchenReadyOrders();

  /* Handlers */

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <Card style={{ flex: 1, margin: 10 }}>
        <FlexView hAlignContent="center" vAlignContent="center">
          <BiHomeAlt
            style={{
              alignSelf: "center",
              margin: 15,
              color: theme.palette.primary.main,
            }}
            fontSize={"large"}
          />
          <Typography variant="h5" style={{ fontWeight: "bolder" }}>
            {t("orderTable.kitchenReady")}
          </Typography>
        </FlexView>

        <TableContainer style={{ height: "75vh" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("orderTable.platform")}</TableCell>
                <TableCell align="center">{t("orderTable.type")}</TableCell>
                <TableCell align="center">{t("orderTable.order")}</TableCell>
                <TableCell align="center">{t("orderTable.brand")}</TableCell>
                <TableCell align="center">{t("orderTable.time")}</TableCell>
                <TableCell
                  style={{ padding: 0, paddingRight: 5 }}
                  align="center"
                >
                  {t("orderTable.pickedup")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kitchenOrders?.data?.map((order: any) => (
                <KitchenReadyRow
                  key={order.id}
                  order={order}
                  setSelectedOrder={setSelectedOrder}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};
