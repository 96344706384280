import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  TextField,
  Stack,
  Card,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { chartSlice } from "../../redux/chart";
import { useTranslation } from "react-i18next";
import DateRangePicker from "@mui/lab/DateRangePicker";

export const RangeSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useAppDispatch();

  const handleClose = (val: string | null) => {
    if (val) {
      dispatch(chartSlice.actions.setRange(val));
    }
    setAnchorEl(null);
  };

  const currentRange = useAppSelector((state) => state.chart.range);
  const customRange = useAppSelector((state) => state.chart.customRange);

  const { t } = useTranslation();

  return (
    <Card sx={{ margin: 1 }}>
      <Stack direction="row" sx={{ marginTop: 1, marginBottom: 1 }} spacing={1}>
        <Button
          variant="contained"
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ height: 55, marginLeft: 1 }}
          color={"success"}
        >
          {`${t("filter.timeInterval")} | ${t(
            `filter.${currentRange}` as any
          )}`}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleClose("today")}>
            {t("filter.today")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("yesterday")}>
            {t("filter.yesterday")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("thisWeek")}>
            {t("filter.thisWeek")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("lastWeek")}>
            {t("filter.lastWeek")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("thisMonth")}>
            {t("filter.thisMonth")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("lastMonth")}>
            {t("filter.lastMonth")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("thisYear")}>
            {t("filter.thisYear")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("lastYear")}>
            {t("filter.lastYear")}
          </MenuItem>
          <MenuItem onClick={() => handleClose("customDate")}>
            {t("filter.customDate")}
          </MenuItem>
        </Menu>
        {currentRange === "customDate" && (
          <DateRangePicker
            startText={t("filter.dateStart")}
            endText={t("filter.dateEnd")}
            value={customRange}
            onChange={(newRange) =>
              dispatch(chartSlice.actions.setCustomRange(newRange))
            }
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> {t("filter.to")} </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        )}
      </Stack>
    </Card>
  );
};
