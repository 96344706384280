import React, { useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCloudCenterId } from "../../../selectors/accountSelector";
import { firestore } from "../../../firebase";
import { collection, query, orderBy } from "firebase/firestore";
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";

interface Props {
  order: any;
}

interface OrderComment {
  id: string;
  user: string;
  createdAt: any;
  comment: string;
}

export const HisComments: React.FC<Props> = ({ order }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState(false);

  /* Selectors */
  const serviceCenterId = useSelector(selectCloudCenterId);

  /* Firestore */
  const commentsRef = collection(
    firestore,
    "cloudCenters",
    serviceCenterId,
    "deliveryOrders",
    order["CloudCenterOrders.id"],
    "comments"
  );

  const commentsQuery = query(commentsRef, orderBy("createdAt"));

  const allComments = useFirestoreQueryData(
    ["comments", order.id],
    commentsQuery,
    {
      subscribe: true,
    }
  );

  const mutation = useFirestoreCollectionMutation(commentsRef);

  /* Handlers */
  const handleSaveComment = () => {
    if (newComment.replace(/\s/g, "").length) {
      mutation.mutate({
        comment: newComment,
        createdAt: new Date(),
      });
      setNewComment(" ");
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell style={{ width: 120 }}>{t("comment.time")}</TableCell>
            <TableCell>{t("comment.comment")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allComments.data?.length === 0 ? (
            <TableRow>
              <TableCell align="center" size="medium" colSpan={5}>
                {t("comment.noComment")}
              </TableCell>
            </TableRow>
          ) : (
            allComments.data?.map((comment, index) => (
              <TableRow hover key={comment.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {dayjs(comment?.createdAt.toDate()).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                </TableCell>
                <TableCell>{comment?.comment} </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <Box style={{ display: "flex", marginTop: 20 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="New-Comment">
            {t("comment.newComment")}
          </InputLabel>
          <OutlinedInput
            value={newComment}
            id="New-Comment"
            label="New Comment"
            autoComplete="off"
            fullWidth
            multiline
            error={error}
            onChange={handleChange}
            style={{ paddingInline: 10, paddingBottom: 5, minHeight: 50 }}
          />
        </FormControl>
        <Button
          style={{ marginLeft: 15 }}
          onClick={() => {
            handleSaveComment();
          }}
        >
          {t("comment.save")}
        </Button>
      </Box>
    </React.Fragment>
  );
};
