import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import zh from "./translations/zh-TW.json";
import dayjs from "dayjs";

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      "zh-TW": { translation: zh },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    supportedLngs: ["en", "zh-TW", "zh"],
    interpolation: {
      escapeValue: false,
      format: (value, format, lng = "en") => {
        if (value instanceof dayjs) {
          //@ts-ignore
          return value.locale(lng.toLowerCase()).format(format);
        }
        return value;
      },
    },
  });

export default i18n;
