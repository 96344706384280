import { CloudCenterOrder } from "@queue/types";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { collection, query, orderBy } from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  useFirestoreCollectionMutation,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
// import { DeliveryDetails } from "../dashboard/kpi/DeliveryDetails";

interface Props {
  order: CloudCenterOrder;
  setSelectedOrder: (value: CloudCenterOrder | null) => void;
}

interface OrderComment {
  id: string;
  user: string;
  createdAt: any;
  comment: string;
}

export const CommentDialogue: React.FC<Props> = ({
  order,
  setSelectedOrder,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* States */
  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState(false);

  /* Selectors */
  const serviceCenterId = useSelector(selectCloudCenterId);

  /* Firestore */
  const commentsRef = collection(
    firestore,
    "cloudCenters",
    serviceCenterId,
    "deliveryOrders",
    order.id,
    "comments"
  );

  const commentsQuery = query(commentsRef, orderBy("createdAt"));

  const allComments = useFirestoreQueryData(
    ["comments", order.id],
    commentsQuery,
    {
      subscribe: true,
    }
  );

  const mutation = useFirestoreCollectionMutation(commentsRef);

  /* Handlers */
  const handleSaveComment = () => {
    const newCommentData = {
      comment: newComment,
      createdAt: new Date(),
    };

    if (newComment.replace(/\s/g, "").length) {
      mutation.mutate(newCommentData);
      setNewComment(" ");
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(event.target.value);
  };

  return (
    <Dialog fullWidth open={true} onClose={() => setSelectedOrder(null)}>
      <DialogTitle>{`${order.name} | ${order.brandName}`}</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("comment.time")}</TableCell>
              <TableCell>{t("orderTable.comment")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allComments.data?.length === 0 ? (
              <TableRow>
                <TableCell align="center" size="medium" colSpan={5}>
                  {t("comment.noComment")}
                </TableCell>
              </TableRow>
            ) : (
              allComments.data?.map((comment) => (
                <TableRow hover key={comment.id}>
                  <TableCell>
                    {dayjs(comment?.createdAt.toDate()).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </TableCell>
                  <TableCell>{comment?.comment} </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Box style={{ display: "flex", marginTop: 20 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="new-Comment">
              {t("comment.newComment")}
            </InputLabel>
            <OutlinedInput
              label={t("comment.newComment")}
              id="new-Comment"
              value={newComment}
              autoComplete="off"
              fullWidth
              multiline
              error={error}
              onChange={handleChange}
              style={{ paddingInline: 10, paddingBottom: 5, minHeight: 50 }}
            />
          </FormControl>
          <Button style={{ marginLeft: 15 }} onClick={handleSaveComment}>
            {t("comment.save")}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setSelectedOrder(null);
          }}
          style={{ marginRight: 14 }}
        >
          {t("comment.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
