import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { brandingDarkTheme, brandingLightTheme } from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Main } from "./Main";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppSelector } from "../hooks/reduxHooks";
import { ReactQueryDevtools } from "react-query/devtools";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";

Sentry.init({
  dsn: "https://96419f08f2b545de84d225a7506c3cdd@o75065.ingest.sentry.io/6149712",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


ReactGA.initialize("G-49LDGRVZ4S");

const queryClient = new QueryClient();

const App: React.FC = () => {
  const darkmode = useAppSelector((state) => state.account.darkmode);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={darkmode ? brandingDarkTheme : brandingLightTheme}>
        {/* @ts-ignore */}
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CssBaseline>
            <Main />
            <ToastContainer limit={3} />
          </CssBaseline>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
