import { Card, Typography, IconProps, IconButton } from "@mui/material";
import React from "react";
import FlexView from "react-flexview";
import { CloudCenterOrder } from "@queue/types";
import { KpiDialog } from "./KpiDialog";
import { useTranslation } from "react-i18next";

interface Props {
  icon: React.ReactElement<IconProps>;
  colour: string;
  count: number;
  label: string;
  disabled: boolean;
  orderList?: CloudCenterOrder[];
}

export const KpiCard: React.FunctionComponent<Props> = ({
  icon,
  colour,
  count,
  label,
  disabled,
  orderList,
}) => {
  /* States */
  const [open, setOpen] = React.useState(false);

  /* Handlers */
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      style={{
        display: "flex",
        marginRight: 20,
        marginBottom: 5,
        flexDirection: "row",
        width: 185,
        maxHeight: 55,
      }}
    >
      {/* ICON FLEX */}
      <FlexView
        style={{
          backgroundColor: colour,
          flex: 1,
        }}
        hAlignContent={"center"}
        vAlignContent={"center"}
      >
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={handleClickOpen}
          disabled={disabled}
        >
          {icon}
        </IconButton>
      </FlexView>

      {/* KPI FLEX */}
      <FlexView
        column
        hAlignContent={"center"}
        style={{
          flex: 3,
        }}
      >
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ fontWeight: "bolder" }}
        >
          {count}
        </Typography>
        <Typography
          component="h6"
          variant="subtitle1"
          style={{ marginTop: -8 }}
        >
          {label}
        </Typography>
      </FlexView>

      {open && (
        <KpiDialog
          open={open}
          orderList={orderList}
          handleClose={handleClose}
          heading={label}
        />
      )}
    </Card>
  );
};
