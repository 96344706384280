import React from "react";
import { KpiCard } from "../KpiCard";
import { BiCar } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { usePickupReadyOrders } from "../../../../hooks/db";

export const PickupReadyCard: React.FC = () => {
  const orders = usePickupReadyOrders();

  const { t } = useTranslation();

  return (
    <KpiCard
      icon={
        <BiCar
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
      colour="#00695c"
      count={orders?.data?.length || 0}
      label={t("kpi.pickup")}
      disabled={true}
    />
  );
};
