import React from "react";
import { useAppQuery } from "../../../hooks/cube";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import { colors } from "./colors";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectDateRange } from "../../../selectors/filterSelector";
import _ from "lodash";

export const BrandSales: React.FC = () => {
  const dateRange = useAppSelector(selectDateRange);
  const { resultSet } = useAppQuery({
    measures: ["Orders.count", "Orders.totalAmount"],
    dimensions: ["BrandGroups.name", "BrandGroups.customColor"],
    segments: ["Orders.paid"],
    filters: [
      {
        member: "CloudCenterOrders.createdAt",
        operator: "inDateRange",
        values: dateRange,
      },
    ],
  });

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return (
      <span style={{ color }}>{`${value || "N/A"} ${_.round(
        entry.payload.percent * 100,
        2
      )}%`}</span>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={resultSet?.chartPivot() || []}
          dataKey={"Orders.totalAmount"}
          nameKey={"xValues.[0]"}
          outerRadius={110}
        >
          {_.sortBy(resultSet?.tablePivot(), "BrandGroups.name").map(
            (e, index) => {
              return (
                <Cell
                  //@ts-ignore
                  fill={e["BrandGroups.customColor"] || colors[index]}
                  key={index}
                />
              );
            }
          )}
        </Pie>
        <Legend
          verticalAlign="middle"
          align="right"
          layout="vertical"
          formatter={renderColorfulLegendText}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
