import React from "react";
import { useAppQuery } from "../../../hooks/cube";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { colors } from "./colors";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectDateRange } from "../../../selectors/filterSelector";

export const TotalSales: React.FC = () => {
  const { t } = useTranslation();
  const dateRange = useAppSelector(selectDateRange);

  const { resultSet, isLoading } = useAppQuery({
    measures: ["CloudCenterOrders.count", "Orders.totalAmount"],
    dimensions: ["CloudCenterOrders.platform"],
    filters: [
      {
        member: "CloudCenterOrders.createdAt",
        operator: "inDateRange",
        values: dateRange,
      },
    ],
  });

  if (isLoading) {
    return null;
  }

  return (
    <ResponsiveContainer height={400} width="100%">
      <PieChart>
        <Legend
          verticalAlign="middle"
          align="right"
          layout="vertical"
          formatter={(value) => {
            return t(value);
          }}
        />
        <Pie
          data={resultSet?.chartPivot() || []}
          dataKey={"Orders.totalAmount"}
          nameKey={"x"}
          outerRadius={110}
        >
          {resultSet?.categories()?.map((val, index) => {
            return (
              <Cell fill={colors[index]} key={`total_sales-${val["x"]}`} />
            );
          })}
        </Pie>
        <Tooltip formatter={(value: any, name: any, props: any) => ["World", t(name)]} />
      </PieChart>
    </ResponsiveContainer>
  );
};
