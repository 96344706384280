import cubejs, { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { useMemo } from "react";
import { auth } from "../firebase";
// import { getUser } from "firebase/auth";

// const PROD_CUBE =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:4000/cubejs-api/v1"
//     : "https://reporting.qpos.me/cubejs-api/v1";

const PROD_CUBE = "https://reporting.qpos.me/cubejs-api/v1";

export const useCubeApi = () => {
  return useMemo(
    () =>
      cubejs(
        async () => {
          const res = await auth.currentUser?.getIdToken();
          if (!res) {
            throw new Error("missing token");
          }
          return res;
        },
        { apiUrl: PROD_CUBE }
      ),
    []
  );
};

export const useAppQuery = <T>(query: Query) => {
  const cubejsApi = useCubeApi();
  return useCubeQuery<T>({ ...query, timezone: "Asia/Taipei" }, { cubejsApi });
};
