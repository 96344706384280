import React from "react";
import queuelogo from "../../images/queuelogo.png";
import { Typography, Stack } from "@mui/material";

export const PoweredByQUEUE: React.FC = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ paddingRight: 1 }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: "bolder" }}>
        {"powered by QUEUE POS"}
      </Typography>
      <img
        src={queuelogo}
        alt="QUEUE"
        style={{
          width: 35,
          height: 35,
        }}
      />
    </Stack>
  );
};
