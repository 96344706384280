import { CloudCenterOrder } from "@queue/types";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const ordersAdapter = createEntityAdapter<CloudCenterOrder>();

export const ordersSlice = createSlice({
  name: "orders",
  initialState: ordersAdapter.getInitialState(),
  reducers: {
    ordersReceived(state, { payload }) {
      ordersAdapter.setAll(state, payload);
    },
  },
});
