import React, { useState } from "react";
import { Kpis } from "./kpi/KpisMain";
import { Pickup } from "./Pickup";
import { KitchenReady } from "./KitchenReady";
import { CloudCenterOrder } from "@queue/types";
import { CommentDialogue } from "../brands/CommentDialogue";
import { Stack } from "@mui/material";

export const Dashboard: React.FC = () => {
  /* States */
  const [selectedOrder, setSelectedOrder] = useState<CloudCenterOrder | null>(
    null
  );

  return (
    <React.Fragment>
      <Kpis />
      <Stack direction="row">
        <KitchenReady setSelectedOrder={setSelectedOrder} />
        <Pickup setSelectedOrder={setSelectedOrder} />
      </Stack>
      {selectedOrder && (
        <CommentDialogue
          order={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}
    </React.Fragment>
  );
};
