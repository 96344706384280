import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloudCenterOrder } from "@queue/types";
import React from "react";
import FlexView from "react-flexview/lib";
import { useTranslation } from "react-i18next";
import { BiCar } from "react-icons/bi";
import { usePickupReadyOrders } from "../../hooks/db";
import { OrderRow } from "../pickUpBoard/OrderRow";

interface Props {
  setSelectedOrder: (value: CloudCenterOrder | null) => void;
}

export const Pickup: React.FC<Props> = ({ setSelectedOrder }) => {
  /* Hooks */
  const { t } = useTranslation();

  const pickupReadyOrders = usePickupReadyOrders();

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <Card style={{ flex: 1, margin: 10 }}>
        <FlexView hAlignContent="center" vAlignContent="center">
          <BiCar
            style={{
              alignSelf: "center",
              margin: 15,
            }}
            fontSize={"large"}
          />
          <Typography variant="h5" style={{ fontWeight: "bolder" }}>
            {t("orderTable.pickupReady")}
          </Typography>
        </FlexView>
        <TableContainer style={{ height: "75vh" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("orderTable.platform")}</TableCell>
                <TableCell align="center">{t("orderTable.type")}</TableCell>
                <TableCell align="center">{t("orderTable.order")}</TableCell>
                <TableCell align="center">{t("orderTable.brand")}</TableCell>
                <TableCell align="center">{t("orderTable.time")}</TableCell>
                <TableCell style={{ padding: 0, paddingRight: 5 }}>
                  {t("orderTable.delivered")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickupReadyOrders?.data?.map((order: any) => (
                <OrderRow
                  key={order.id}
                  order={order}
                  setSelectedOrder={setSelectedOrder}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};
