import React from "react";
import { KpiCard } from "../KpiCard";
import { BiTimer } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { usePendingOrders } from "../../../../hooks/db";

export const PendingCard: React.FC = () => {
  const pendingOrders = usePendingOrders();

  const { t } = useTranslation();

  return (
    <KpiCard
      icon={
        <BiTimer
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
      colour="#25828F"
      count={pendingOrders.data?.length || 0}
      label={t("kpi.pending")}
      disabled={false}
      //@ts-ignore
      orderList={pendingOrders?.data || []}
    />
  );
};
