import { CloudCenterOrder, CloudCenterOrderStatus } from "@queue/types";
import { Card, Typography, CardHeader, Stack, Box } from "@mui/material";
import { LogoImage } from "../brands/Logo";
import React from "react";

interface Props {
  order: CloudCenterOrder;
  cardWidth: number;
}

export const OrderCard: React.FC<Props> = ({ order, cardWidth }) => {
  return (
    <Card
      style={{
        marginBottom: 5,
        marginLeft: 5,
        width: cardWidth,
      }}
    >
      <CardHeader
        avatar={<LogoImage platform={order.platform} width={40} />}
        title={order.brandName}
        titleTypographyProps={{ variant: "h5", noWrap: true }}
        sx={{ margin: 0, padding: 0.5 }}
      />
      <Box sx={{ margin: 0.5 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
            {order.name}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};
