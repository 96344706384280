import { createSlice } from "@reduxjs/toolkit";

interface State {
  range:
    | "today"
    | "yesterday"
    | "thisWeek"
    | "lastWeek"
    | "thisMonth"
    | "lastMonth"
    | "thisYear"
    | "lastYear"
    | "customDate";
  customRange: [number, number];
}

const initialState: State = {
  range: "thisWeek",
  customRange: [Date.now(), Date.now()],
};

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    setRange: (state, { payload }) => {
      state.range = payload;
    },
    setCustomRange: (state, { payload }) => {
      state.customRange = payload;
    },
  },
});
