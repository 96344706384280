import { Checkbox, Chip, ListItem, ListItemText } from "@mui/material";
import React, { useCallback, useState } from "react";
import { IWorkitem } from "@queue/types";

interface WorkListItemProps {
  workitem: IWorkitem;
}

export const WorkListItem: React.FC<WorkListItemProps> = ({ workitem }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const handleClick = useCallback(() => {
    setIsCompleted(!isCompleted);
  }, [isCompleted]);

  return (
    <ListItem
      onClick={handleClick}
      divider
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={handleClick}
          checked={isCompleted}
          color="success"
          size={"medium"}
        />
      }
    >
      <Chip
        label={workitem.quantity}
        sx={{
          fontWeight: "bolder",
          fontSize: 16,
          marginLeft: !!workitem.parentLineItem ? 4 : 0,
        }}
        color={
          // workitem.workstatus === WorkStatus.Completed ? "success" : "info"
          isCompleted ? "success" : "warning"
        }
      />

      <ListItemText
        sx={{
          // textDecoration: isCompleted ? "line-through" : "none",
          marginLeft: 1,
        }}
      >
        {workitem.productName}
      </ListItemText>
    </ListItem>
  );
};
