import { RootState } from "../redux/store";
import dayjs from "dayjs";
import { createSelector } from "@reduxjs/toolkit";

export const selectBrandNamesFilter = (state: RootState) => {
  return state.hisFilter.selectedBrands;
};

export const selectOrderStatusFilter = (state: RootState) => {
  return state.hisFilter.orderStatus;
};

export const selectOrderTypeFilter = (state: RootState) => {
  return state.hisFilter.orderType;
};

export const selectPlatformFilter = (state: RootState) => {
  return state.hisFilter.platform;
};

export const selectDateFilter = (state: RootState) => {
  return state.hisFilter.date;
};

export const selectRangeFromTime = (state: RootState) => {
  if (state.hisFilter.date === "today") {
    return dayjs().tz().startOf("day").valueOf();
  }

  return state.hisFilter.fromDate;
};

export const selectRangeToTime = (state: RootState) => {
  if (state.hisFilter.date === "today") {
    return dayjs().tz().endOf("day").valueOf();
  }

  return state.hisFilter.toDate;
};

const formatString = "YYYY-MM-DD HH:mm";

export const selectDateRange = createSelector(
  [
    (state: RootState) => state.chart.range,
    (state: RootState) => state.chart.customRange,
  ],
  (interval, customRange) => {
    if (interval === "today") {
      return [
        dayjs().startOf("day").startOf("day").format(formatString),
        dayjs().endOf("day").endOf("day").format(formatString),
      ];
    } else if (interval === "yesterday") {
      return [
        dayjs().subtract(1, "day").startOf("day").format(formatString),
        dayjs().subtract(1, "day").endOf("day").format(formatString),
      ];
    } else if (interval === "thisWeek") {
      return [
        dayjs().startOf("week").format(formatString),
        dayjs().endOf("week").format(formatString),
      ];
    } else if (interval === "thisMonth") {
      return [
        dayjs().startOf("month").format(formatString),
        dayjs().endOf("month").format(formatString),
      ];
    } else if (interval === "lastWeek") {
      return [
        dayjs().subtract(1, "week").startOf("week").format(formatString),
        dayjs().subtract(1, "week").endOf("week").format(formatString),
      ];
    } else if (interval === "lastMonth") {
      return [
        dayjs().subtract(1, "month").startOf("month").format(formatString),
        dayjs().subtract(1, "month").endOf("month").format(formatString),
      ];
    } else if (interval === "thisYear") {
      return [
        dayjs().startOf("year").format(formatString),
        dayjs().endOf("year").format(formatString),
      ];
    } else if (interval === "lastYear") {
      return [
        dayjs().subtract(1, "year").startOf("year").format(formatString),
        dayjs().subtract(1, "year").endOf("year").format(formatString),
      ];
    } else if (interval === "customDate") {
      return [
        dayjs(customRange[0]).startOf("day").format(formatString),
        dayjs(customRange[1]).endOf("day").format(formatString),
      ];
    }

    return [
      dayjs().startOf("day").format(formatString),
      dayjs().endOf("day").format(formatString),
    ];
  }
);
