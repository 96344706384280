import React from "react";
import { KpiCard } from "../KpiCard";
import { BiHomeAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useKitchenReadyOrders } from "../../../../hooks/db";

export const KitchenReadyCard: React.FC = () => {
  const orders = useKitchenReadyOrders();
  const { t } = useTranslation();

  return (
    <KpiCard
      icon={
        <BiHomeAlt
          style={{ alignSelf: "center", margin: 10, color: "white" }}
          fontSize={"large"}
        />
      }
      colour="#F26430"
      count={orders?.data?.length || 0}
      label={t("kpi.kitchenReady")}
      disabled={true}
    />
  );
};
