import { createSlice } from "@reduxjs/toolkit";

export interface IdNameMap {
  [key: string]: string;
}

interface AccountState {
  cloudCenterId: string;
  cloudCenterLogoURL: string;
  loading: boolean;
  stores: IdNameMap;
  brands: IdNameMap;
  storeIds: string[];
  darkmode: boolean;
  webEnabled: boolean;
}

export const initialState: AccountState = {
  cloudCenterId: "",
  cloudCenterLogoURL: "",
  loading: true,
  stores: {},
  brands: {},
  storeIds: [],
  darkmode: false,
  webEnabled: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setCloudCenterId: (state, { payload }) => {
      state.cloudCenterId = payload;
      state.loading = false;
    },
    setCloudCenterLogo: (state, { payload }) => {
      state.cloudCenterLogoURL = payload;
    },
    setStores: (state, { payload }) => {
      state.stores = payload;
    },
    setBrands: (state, { payload }) => {
      state.brands = payload;
    },
    setStoreIds: (state, { payload }) => {
      state.storeIds = payload;
    },
    toggleDarkmode: (state) => {
      state.darkmode = !state.darkmode;
    },
    setWebEnabled: (state, { payload }) => {
      state.webEnabled = payload;
    },
  },
});
