import { Button, IconButton, TableCell, TableRow } from "@mui/material";
import { CloudCenterOrder, CloudCenterOrderStatus } from "@queue/types";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiArrowRightCircle } from "react-icons/fi";
import Timeago from "react-timeago";
import { firestore } from "../../firebase";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { LogoImage } from "../brands/Logo";
import { OrderStatusIcon } from "../brands/OrderTypeLogo";

interface Props {
  order: CloudCenterOrder;
  setSelectedOrder(order: CloudCenterOrder): void;
}

export const KitchenReadyRow: React.FC<Props> = ({
  order,
  setSelectedOrder,
}) => {
  const cloudCenterId = useAppSelector(selectCloudCenterId);

  const { t } = useTranslation();

  const orderRef = doc(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders",
    order.id
  );

  const mutation = useFirestoreDocumentMutation(orderRef, { merge: true });

  const handleFrontdeskReady = () => {
    mutation.mutate({
      status: CloudCenterOrderStatus.frontdeskReady,
      frontdeskReadyAt: new Date(),
    });
  };

  return (
    <React.Fragment key={order.id}>
      <TableRow hover>
        <TableCell align="center">
          <LogoImage platform={order.platform} width={30} />
        </TableCell>
        <TableCell align="center">
          <OrderStatusIcon orderType={order.orderType} />
        </TableCell>
        <TableCell align="center">{order.name}</TableCell>
        <TableCell align="center">{order.brandName}</TableCell>
        <TableCell align="center" onClick={() => setSelectedOrder(order)}>
          <Timeago
            date={order.kitchenReadyAt?.toDate()}
            formatter={(value, unit) => {
              if (unit === "second" && value < 60) return t("timeago.justNow");
              if (unit === "minute") return `${value} ` + t("timeago.minAgo");
              if (unit === "hour") return `${value} ` + t("timeago.hourAgo");
              if (unit === "day") return `${value} ` + t("timeago.dayAgo");
              if (unit === "week") return `${value} ` + t("timeago.weekAgo");
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ padding: 0, paddingRight: 5 }}>
          <IconButton onClick={handleFrontdeskReady}>
            <FiArrowRightCircle color={"green"} />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* // {order?.storeBrandIds?.map((storeBrand) => { */}
      {/* //   const storeName = storesMap[storeBrand.storeId]; */}
      {/* //   const brandName = brandMap[storeBrand.brandId]; */}

      {/* //   return ( */}
      {/* //     <TableRow key={`${storeBrand.storeId}-${storeBrand.brandId}`}> */}
      {/* //       <TableCell /> */}
      {/* //       <TableCell /> */}
      {/* //       <TableCell /> */}
      {/* //       <TableCell /> */}
      {/* //       <TableCell align="center">{storeName}</TableCell> */}
      {/* //       <TableCell>{brandName}</TableCell> */}
      {/* //       <TableCell /> */}
      {/* //     </TableRow> */}
      {/* //   ); */}
      {/* // })} */}
    </React.Fragment>
  );
};
