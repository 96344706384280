import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PickUpDashboard } from "../components/pickUpBoard/PickUpDashboard";
import { Dashboard } from "../components/dashboard/Dashboard";
import { Navigation } from "../components/navigation/Navigation";
import { LoginScreen } from "../components/login/LoginScreen";
import { UserNotFound } from "../components/login/UserNotFound";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { accountSlice, IdNameMap } from "../redux/accountSlice";
import { useDispatch } from "react-redux";
import {
  selectCloudCenterId,
  selectCloudCenterLoading,
} from "../selectors/accountSelector";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { PackageDashboard } from "../components/packaging/PackageDashboard";
import { useAuthUser } from "@react-query-firebase/auth";
import { CloudCenter } from "../../../functions/src/types";
import { useAppQuery } from "../hooks/cube";
import { CompletedOrders } from "../components/history/CompletedOrders";
import { collection, doc, query, where } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import { Box } from "@mui/material";

interface IdName {
  id: string;
  name: string;
}

export const Main: React.FC = () => {
  const user = useAuthUser(["user"], auth);

  const dispatch = useDispatch();

  useEffect(() => {
    const getClaim = async () => {
      if (user.data) {
        const token = await auth.currentUser?.getIdTokenResult();
        const cloudCenterId = token?.claims?.cloudCenterId as string;

        const storeIds = token?.claims?.storeIds as string[];

        dispatch(accountSlice.actions.setStoreIds(storeIds));
        dispatch(accountSlice.actions.setCloudCenterId(cloudCenterId || ""));
      }
    };
    getClaim();
  }, [user, dispatch]);

  if (user.isLoading) {
    return null;
  }

  return user.data ? <AuthenticatedUser /> : <LoginScreen />;
};

const AuthenticatedUser: React.FC = () => {
  /* Hooks */
  const handleFullScreen = useFullScreenHandle();
  const reduxCloudCenterId = useAppSelector(selectCloudCenterId);

  const isLoading = useAppSelector(selectCloudCenterLoading);

  if (!reduxCloudCenterId && isLoading) {
    return null;
  }

  if (!reduxCloudCenterId && !isLoading) {
    return <UserNotFound />;
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
      }}
    >
      <BrowserRouter>
        <SyncCloudCenter cloudCenterId={reduxCloudCenterId} />
        <Navigation />
        <Routes>
          <Route path="/" element={<Dashboard />} />

          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path={"/pickup"}
            element={
              <FullScreen handle={handleFullScreen}>
                <PickUpDashboard handleFullScreen={handleFullScreen} />
              </FullScreen>
            }
          />

          <Route path="/completedOrders" element={<CompletedOrders />} />

          <Route path="/packaging" element={<PackageDashboard />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
};

interface SyncProps {
  cloudCenterId: string;
}

const SyncCloudCenter: React.FC<SyncProps> = ({ cloudCenterId }) => {
  const dispatch = useAppDispatch();
  const cloudCenterRef = doc(firestore, "cloudCenters", cloudCenterId);

  const cloudCenter = useFirestoreDocumentData<any, CloudCenter>(
    ["cloudCenters", cloudCenterId],
    cloudCenterRef,
    { subscribe: true }
  );

  const { resultSet: cubeResult } = useAppQuery<IdName>({
    dimensions: ["Stores.id", "Stores.name"],
  });

  const storeIds = useAppSelector((state) => state.account.storeIds);

  const brandsRef = collection(
    firestore,
    "externalPlatforms",
    "customIntegrations",
    "brands"
  );
  const brandsQuery = query(brandsRef, where("storeId", "in", storeIds));

  const brands = useFirestoreQueryData<"id">(["brands"], brandsQuery, {
    idField: "id",
    subscribe: true,
  });

  useEffect(() => {
    if (cloudCenter?.data?.logoURL) {
      dispatch(
        accountSlice.actions.setCloudCenterLogo(cloudCenter?.data.logoURL)
      );
    }
    if (cloudCenter?.data?.webEnabled) {
      dispatch(accountSlice.actions.setWebEnabled(true));
    }
  }, [cloudCenter, dispatch]);

  useEffect(() => {
    if (cubeResult) {
      const storesMap: IdNameMap = {};

      cubeResult.tablePivot().forEach((store) => {
        //@ts-ignore
        storesMap[store["Stores.id"]] = store["Stores.name"];
      });
      dispatch(accountSlice.actions.setStores(storesMap));
    }
  }, [dispatch, cubeResult]);

  useEffect(() => {
    if (brands.data) {
      const mapData: IdNameMap = {};

      brands.data?.forEach((brand) => {
        mapData[brand.id] = brand.name;
      });
      dispatch(accountSlice.actions.setBrands(mapData));
    }
  }, [dispatch, brands]);

  return null;
};
