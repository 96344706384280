import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { CloudCenterOrder } from "@queue/types";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { firestore } from "../../firebase";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { DeliveryDetails } from "../dashboard/kpi/DeliveryDetails";

interface Props {
  handleClose(): void;
  order: CloudCenterOrder;
}

export const DeliveryOrderForm: React.FC<Props> = ({ order, handleClose }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      deliveryMethod: order.deliveryMethod || "none",
      frontdeskNote: order.deliveryNote || "",
    },
  });

  const cloudCenterId = useAppSelector(selectCloudCenterId);

  const { t } = useTranslation();

  const orderRef = doc(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders",
    order.id
  );

  const mutation = useFirestoreDocumentMutation(orderRef, { merge: true });

  const onSubmit = (values: any) => {
    mutation.mutate({
      deliveryMethod: values.deliveryMethod,
      frontdeskNote: values.frontdeskNote,
    });
    handleClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{order.name}</DialogTitle>
        <DialogContent sx={{ minHeight: 400 }}>
          <Stack spacing={2}>
            <DeliveryDetails order={order} />
            <Controller
              control={control}
              name="deliveryMethod"
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {t("deliveryMethod")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="delivery"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value={"courier"}
                        control={<Radio />}
                        label={t("courierDelivery")}
                      />
                      <FormControlLabel
                        value="self"
                        control={<Radio />}
                        label={t("selfDelivery")}
                      />
                      <FormControlLabel
                        value="none"
                        control={<Radio />}
                        label={t("notSpecified")}
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />
            <TextField
              {...register("frontdeskNote")}
              label={t("frontdeskNote")}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </>
  );
};
