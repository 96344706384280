import React, { useState } from "react";
import {
  Card,
  Button,
  Container,
  CardActions,
  CardContent,
  Stack,
  TextField,
  CardHeader,
} from "@mui/material";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import queuelogo from "../../images/queuelogo.png";

export const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setError("Error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ marginTop: 3 }}>
        <CardHeader title="QUEUE Cloud center" />
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <img
              src={queuelogo}
              alt="QUEUE"
              style={{ height: 150, width: 150 }}
            />
            <Stack spacing={3} sx={{ flexGrow: 1 }}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email address"
                type="email"
              />
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                type="password"
              />
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            disabled={loading}
            onClick={handleLogin}
            sx={{ flex: 1 }}
            variant="contained"
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};
