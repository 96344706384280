import {
  Button,
  Card,
  Typography,
  Divider,
  List,
  CardHeader,
  CardActions,
} from "@mui/material";
import React from "react";
import FlexView from "react-flexview/lib";
import {
  CloudCenterOrder,
  CloudCenterOrderStatus,
  IOrder,
  IWorkitem,
  OrderSourceType,
  QmeOrderStatus,
} from "@queue/types";
import { BiGridAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { firestore } from "../../firebase";
import { WorkListItem } from "./PackageWorkListItem";
import { LogoImage } from "../brands/Logo";
import {
  collection,
  collectionGroup,
  doc,
  getDocs,
  getDoc,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  useFirestoreDocumentData,
  useFirestoreQueryData,
  useFirestoreWriteBatch,
} from "@react-query-firebase/firestore";
import _ from "lodash";

interface Props {
  order: CloudCenterOrder;
  cardWidth: number;
  workitems: IWorkitem[];
  posOrder?: IOrder;
}

interface PackageCardProps {
  order: CloudCenterOrder;
  cardWidth: number;
}

export const CustomPackagingCard: React.FC<PackageCardProps> = (props) => {
  /* Firebase get WorkItems */
  const workitemsRef = collectionGroup(firestore, "workitems");
  const workitemsQuery = query(
    workitemsRef,
    where("order", "in", props.order.storeOrderIds),
    orderBy("sortKey")
  );

  const workitems = useFirestoreQueryData<IWorkitem[], IWorkitem[]>(
    ["workitems", props.order.id],
    //@ts-ignore
    workitemsQuery,
    { subscribe: true, idField: "id" },
    { cacheTime: 0 }
  );

  if (workitems.data) {
    return <PackageCard {...props} workitems={workitems.data} />;
  } else {
    return null;
  }
};

export const RegularPackagingCard: React.FC<PackageCardProps> = (props) => {
  /* Firebase get WorkItems */
  const workitemsRef = collectionGroup(firestore, "workitems");
  const workitemsQuery = query(
    workitemsRef,
    where("order", "==", props.order.id),
    orderBy("sortKey")
  );

  const workitems = useFirestoreQueryData<IWorkitem[], IWorkitem[]>(
    ["workitems", props.order.id],
    //@ts-ignore
    workitemsQuery,
    { subscribe: true, idField: "id" },
    { cacheTime: 0 }
  );

  //@ts-ignore
  const posOrderRef = doc(
    firestore,
    "stores",
    props.order.storeId,
    "orders",
    props.order.id
  );

  const posOrder = useFirestoreDocumentData<any>(
    ["posOrders", props.order.id],
    posOrderRef,
    { subscribe: true },
    { cacheTime: 0 }
  );

  if (workitems.data) {
    return (
      <PackageCard
        {...props}
        workitems={workitems.data}
        posOrder={posOrder?.data}
      />
    );
  } else {
    return null;
  }
};

const getPlatformRef = (order: CloudCenterOrder) => {
  if (order.platform === OrderSourceType.UBER) {
    //@ts-ignore
    return doc(firestore, "stores", order.storeId, "uberEats", order.id);
  } else if (order.platform === OrderSourceType.DELIVERY_HERO) {
    //@ts-ignore
    return doc(firestore, "stores", order.storeId, "deliveryHero", order.id);
    //@ts-ignore
  } else if (order.platform === OrderSourceType.Custom) {
    return null;
    ////@ts-ignore
    //return doc(
    //  firestore,
    //  "stores",
    //  order.storeId,
    //  "customExternalOrders",
    //  order.id
    //);
  }
};

export const PackageCard: React.FC<Props> = ({
  order,
  cardWidth,
  workitems,
  posOrder,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const batch = writeBatch(firestore);

  /* Selector */
  const cloudCenterId = useSelector(selectCloudCenterId);

  const mutation = useFirestoreWriteBatch(batch);

  const cloudCenterOrderRef = doc(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders",
    order.id
  );

  const externalOrderRef = getPlatformRef(order);

  /* Handlers */
  const handleFrontdeskReady = async () => {
    batch.update(cloudCenterOrderRef, {
      status: CloudCenterOrderStatus.frontdeskReady,
      frontdeskReadyAt: new Date(),
    });

    if (externalOrderRef) {
      batch.update(externalOrderRef, {
        status: QmeOrderStatus.Completed,
        completedAt: new Date(),
        complete: true,
        readyAt: new Date(),
        readyBy: "frontdesk",
      });
    }

    const openOrderRef =
      //@ts-ignore
      doc(
        firestore,
        "stores",
        order.storeId,
        "openItems",
        "cart",
        "openOrders",
        order.id
      );

    const openOrderSnap = await getDoc(openOrderRef);

    if (openOrderSnap?.exists?.()) {
      batch.delete(openOrderRef);
      //@ts-ignore
      const openLineitemsRef = collection(
        firestore,
        "stores",
        order.storeId,
        "openItems",
        "cart",
        "openLineItems"
      );

      const openLineitemsQuery = query(
        openLineitemsRef,
        where("order", "==", order.id)
      );

      const lineitemsSnap = await getDocs(openLineitemsQuery);

      lineitemsSnap.forEach((snap) => {
        batch.delete(snap.ref);
      });
    }

    mutation.mutate();
  };

  let orderTitle = order.name;

  if (posOrder?.orderNumber) {
    orderTitle = `${orderTitle} | ${_.padStart(
      posOrder?.orderNumber + "",
      4,
      "0"
    )}`;
  }

  if (!(workitems?.length > 0)) {
    return null;
  }

  return (
    <Card
      style={{
        margin: 5,
        width: cardWidth,
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        title={orderTitle}
        subheader={order.brandName}
        subheaderTypographyProps={{ noWrap: true }}
        avatar={<LogoImage platform={order.platform} width={40} />}
        action={
          <FlexView vAlignContent="center" style={{ paddingTop: 3 }}>
            <BiGridAlt />
            <Typography
              variant="subtitle1"
              style={{ textAlign: "right", marginLeft: 2 }}
            >
              {workitems.length}
            </Typography>
          </FlexView>
        }
        titleTypographyProps={{ variant: "h6" }}
      />

      {!!posOrder?.comment && <Typography>{posOrder?.comment}</Typography>}

      <Divider />

      <List>
        {workitems?.map((workitem) => (
          <WorkListItem key={workitem.id} workitem={workitem} />
        ))}
      </List>

      {/* BOTTOM */}
      <div
        style={{
          marginTop: "auto",
        }}
      >
        <CardActions>
          <Button
            onClick={handleFrontdeskReady}
            variant="contained"
            style={{ flex: 1 }}
            color="success"
          >
            {t("package.complete")}
          </Button>
        </CardActions>
      </div>
    </Card>
  );
};
