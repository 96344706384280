import { IconButton, TableCell, TableRow } from "@mui/material";
import { CloudCenterOrder, CloudCenterOrderStatus } from "@queue/types";
import React from "react";
import { toast } from "react-toastify";
import { LogoImage } from "../brands/Logo";
import { OrderStatusIcon } from "../brands/OrderTypeLogo";
import Timeago from "react-timeago";
import { firestore } from "../../firebase";
import { deleteField, doc } from "firebase/firestore";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { useTranslation } from "react-i18next";
import { FiArrowRightCircle } from "react-icons/fi";

interface Props {
  order: CloudCenterOrder;
  setSelectedOrder(order: CloudCenterOrder): void;
}
export const OrderRow: React.FC<Props> = ({ order, setSelectedOrder }) => {
  const cloudCenterId = useAppSelector(selectCloudCenterId);

  const { t } = useTranslation();

  const orderRef = doc(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders",
    order.id
  );
  const mutation = useFirestoreDocumentMutation(orderRef, { merge: true });

  const handleOrderDelivered = () => {
    mutation.mutate({
      status: CloudCenterOrderStatus.frontdeskDelivered,
      completedAt: new Date(),
    });
  };

  const handleUndoReady = () => {
    mutation.mutate({
      status: CloudCenterOrderStatus.kitchenReady,
      completedAt: deleteField(),
    });
  };

  return (
    <TableRow hover key={order.id}>
      <TableCell align="center">
        <LogoImage platform={order.platform} width={30} />
      </TableCell>
      <TableCell align="center">
        <OrderStatusIcon orderType={order.orderType} />
      </TableCell>
      <TableCell align="center">{order.name}</TableCell>
      <TableCell align="center">{order.brandName}</TableCell>
      <TableCell align="center" onClick={() => setSelectedOrder(order)}>
        <Timeago
          date={order.frontdeskReadyAt?.toDate()}
          formatter={(value, unit) => {
            if (unit === "second" && value < 60) return t("timeago.justNow");
            if (unit === "minute") return `${value} ` + t("timeago.minAgo");
            if (unit === "hour") return `${value} ` + t("timeago.hourAgo");
            if (unit === "day") return `${value} ` + t("timeago.dayAgo");
            if (unit === "week") return `${value} ` + t("timeago.weekAgo");
          }}
        />
      </TableCell>
      <TableCell align="center" style={{ padding: 0, paddingRight: 5 }}>
        <IconButton
          onClick={() => {
            toast.success(
              "Order " + order.name + " ready for Pickup (click to undo)",
              {
                position: "bottom-right",
                onClick: handleUndoReady,
              }
            );
            handleOrderDelivered();
          }}
        >
          <FiArrowRightCircle color={"green"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
