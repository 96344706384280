import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface Props {
  order: any;
}

export const HisTimestamp: React.FunctionComponent<Props> = ({ order }) => {
  /* Hooks */
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("orderTable.process")}</TableCell>
            <TableCell>{t("orderTable.time")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t("orderTable.processTime.receivedAt")}</TableCell>
            <TableCell>
              {t("dates.timeDate", {
                date: dayjs(order["CloudCenterOrders.createdAtTz"]),
              })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("orderTable.processTime.acceptedAt")}</TableCell>
            <TableCell>
              {t("dates.timeDate", {
                date: dayjs(order["CloudCenterOrders.kitchenAcceptedAtTz"]),
              })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("orderTable.processTime.readyAt")}</TableCell>
            <TableCell>
              {t("dates.timeDate", {
                date: dayjs(order["CloudCenterOrders.kitchenReadyAtTz"]),
              })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("orderTable.processTime.pickupAt")}</TableCell>
            <TableCell>
              {t("dates.timeDate", {
                date: dayjs(order["CloudCenterOrders.frontdeskReadyAtTz"]),
              })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("orderTable.processTime.completedAt")}</TableCell>
            <TableCell>
              {t("dates.timeDate", {
                date: dayjs(order["CloudCenterOrders.completedAtTz"]),
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
