import { CloudCenterOrder, CloudCenterOrderStatus } from "@queue/types";
import {
  useFirestoreQuery,
  useFirestoreQueryData,
} from "@react-query-firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { selectCloudCenterId } from "../selectors/accountSelector";
import { useAppSelector } from "./reduxHooks";
import { useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";

export const useOrdersQuery = (select?: any) => {
  const cloudCenterId = useAppSelector(selectCloudCenterId);
  const ordersCollection = collection(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders"
  );

  const ordersQuery = query(
    ordersCollection,
    where("status", "in", [
      CloudCenterOrderStatus.scheduled,
      CloudCenterOrderStatus.pendingOther,
      CloudCenterOrderStatus.kitchenReady,
      CloudCenterOrderStatus.kitchenPending,
      CloudCenterOrderStatus.kitchenAccepted,
      CloudCenterOrderStatus.frontdeskReady,
    ])
  );

  return useFirestoreQueryData(
    ["orders", cloudCenterId],
    ordersQuery,
    {
      subscribe: true,
      idField: "id",
    },
    { select, enabled: !!cloudCenterId, cacheTime: 0 }
  );
};

export const useTodayOrders = (select?: any) => {
  const cloudCenterId = useAppSelector(selectCloudCenterId);
  const ordersCollection = collection(
    firestore,
    "cloudCenters",
    cloudCenterId,
    "deliveryOrders"
  );

  const [today, setToday] = useState(dayjs().startOf("day").toDate());

  const ordersQuery = query(ordersCollection, where("completedAt", ">", today));

  return useFirestoreQueryData(
    ["todayOrders", cloudCenterId],
    ordersQuery,
    { subscribe: true },
    { select, enabled: !!cloudCenterId, cacheTime: 0 }
  );
};

export const usePendingOrders = () =>
  useOrdersQuery((data: any) =>
    _.sortBy(
      data.filter((o: any) =>
        [
          CloudCenterOrderStatus.kitchenPending,
          CloudCenterOrderStatus.pendingOther,
        ].includes(o.status)
      ),
      "createdAt"
    )
  );

export const useKitchenReadyOrders = () =>
  useOrdersQuery((data: any) =>
    _.sortBy(
      data.filter((o: any) =>
        [CloudCenterOrderStatus.kitchenReady].includes(o.status)
      ),
      "kitchenReadyAt"
    )
  );

const selectFrontdeskReady = (data: any) =>
  _.sortBy(
    data.filter((o: any) =>
      [CloudCenterOrderStatus.frontdeskReady].includes(o.status)
    ),
    "frontdeskReadyAt"
  );

export const usePickupReadyOrders = () => useOrdersQuery(selectFrontdeskReady);

export const useCancelledOrders = () =>
  useTodayOrders((data: any) =>
    data.filter(
      (o: any) =>
        o.status === CloudCenterOrderStatus.platformCancelled ||
        o.status === CloudCenterOrderStatus.kitchenDeclined ||
        o.status === CloudCenterOrderStatus.kitchenCancelled
    )
  );

export const usePreparingOrders = () =>
  useOrdersQuery((data: any) =>
    _.sortBy(
      data.filter(
        (o: any) => o.status === CloudCenterOrderStatus.kitchenAccepted
      ),
      "kitchenAcceptedAt"
    )
  );

export const useAllPreparingOrders = () =>
  useOrdersQuery((data: any) =>
    data.filter((o: any) =>
      [
        CloudCenterOrderStatus.kitchenAccepted,
        CloudCenterOrderStatus.kitchenReady,
      ].includes(o.status)
    )
  );

export const useWebOrders = () => {
  return useOrdersQuery((data: any) =>
    _.sortBy(
      data.filter((o: any) => o.platform === "CUSTOM"),
      "scheduledAt"
    )
  );
};

export const usePackagingOrders = () => {
  return useOrdersQuery((data: any) =>
    _.sortBy(
      data.filter(
        (o: any) =>
          o.status === CloudCenterOrderStatus.kitchenReady ||
          o.status === CloudCenterOrderStatus.kitchenAccepted
      ),
      "createdAt"
    )
  );
};
