import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CloudCenterOrder, ConsumerOrderType } from "@queue/types";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCycling, BiWalk, } from "react-icons/bi";
import { useDialog } from "use-mui";
import { useWebOrders } from "../../../../hooks/db";
import { DeliveryOrderForm } from "../../../deliveryOrders/DeliveryOrderForm";

export const WebOrdersCard: React.FC = () => {
  const orders: any = useWebOrders();

  const { handleClose, open, setOpen } = useDialog();

  const [selectedOrder, setShowOrder] = useState<CloudCenterOrder | null>(null);

  const closeDialog = () => {
    handleClose();
    setShowOrder(null);
  };

  const { t } = useTranslation();

  const count = orders?.data?.length;

  return (
    <>
      <Card
        onClick={() => setOpen(true)}
        style={{
          display: "flex",
          marginRight: 20,
          marginBottom: 5,
          flexDirection: "row",
          width: 185,
          height: 55,
        }}
      >
        {/* ICON FLEX */}
        <Box
          sx={{
            display: "flex",
            backgroundColor: "blue",
            height: "100%",
            width: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "bolder", color: "#fff" }}
          >
            {count}
          </Typography>
        </Box>

        {/* KPI FLEX */}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography component="h6" variant="subtitle1">
            {t("webOrders")}
          </Typography>
        </Box>
      </Card>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
        {selectedOrder ? (
          <DeliveryOrderForm
            order={selectedOrder}
            handleClose={() => setShowOrder(null)}
          />
        ) : (
          <>
            <DialogTitle>{t("webOrders")}</DialogTitle>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{"Type"}</TableCell>
                  <TableCell>{t("scheduledAt")}</TableCell>
                  <TableCell>{t("orderId")}</TableCell>
                  <TableCell>{t("orderTable.name")}</TableCell>
                  <TableCell>{t("orderTable.comment")}</TableCell>
                  <TableCell>{t("deliveryMethod")}</TableCell>
                  <TableCell>{t("deliveryNote")}</TableCell>
                  <TableCell>{t("frontdeskNote")}</TableCell>
                  <TableCell>{t("orderTable.status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.data?.map((o: any) => {
                  return (
                    <TableRow key={o.id} onClick={() => setShowOrder(o)}>
                      <TableCell>
                        <IconButton>
                          {o.orderType === ConsumerOrderType.SelfDelivery ? (
                            <BiCycling color={"green"} />
                          ) : (
                            <BiWalk color={"green"} />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {t("dates.timeDate", {
                          date: dayjs(
                            (o.scheduledAt && o.scheduledAt.toDate()) || 0
                          ),
                        })}
                      </TableCell>
                      <TableCell>{o.name}</TableCell>
                      {/* @ts-ignore */}
                      <TableCell>{o.invoiceInfo?.buyerName}</TableCell>
                      <TableCell>{o.comment || ""}</TableCell>
                      <TableCell>
                        {t((o.deliveryMethod || "noDelivery") as any)}
                      </TableCell>
                      <TableCell>{o?.customer?.deliveryNotes || ""}</TableCell>
                      <TableCell>{o?.frontdeskNote || ""}</TableCell>
                      <TableCell>
                        {t(`orderTable.statusType.${o.status}` as any)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <DialogActions>
              <Button onClick={closeDialog}>{t("comment.close")}</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
