import { ConsumerOrderType } from "@queue/types";
import React from "react";
import { MdOutlineMoped, MdOutlineHail } from "react-icons/md";

interface Props {
  orderType: ConsumerOrderType;
}
export const OrderStatusIcon: React.FC<Props> = ({ orderType }) => {
  if (
    orderType === ConsumerOrderType.PlatformDelivery ||
    orderType === ConsumerOrderType.Delivery
  ) {
    return <MdOutlineMoped size={30} />;
  } else if (orderType === ConsumerOrderType.Pickup) {
    return <MdOutlineHail size={30} />;
  } else return <MdOutlineMoped size={30} />;
};
