import React, { useState } from "react";
import { CloudCenterOrder } from "@queue/types";
import { useTranslation } from "react-i18next";
import { OrderDetailsDialog } from "./OrderDetails/OrderDetailsDialog";
import MaterialTable, { Column } from "@material-table/core";
import { LogoImage } from "../brands/Logo";
import { OrderStatusIcon } from "../brands/OrderTypeLogo";
//@ts-ignore
import ExportCsv from "@material-table/exporters/csv";
import { useAppQuery } from "../../hooks/cube";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectDateRange } from "../../selectors/filterSelector";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { selectCloudCenterId } from "../../selectors/accountSelector";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

const dateFormatMap: any = {
  "zh-TW": "zh-TW",
  en: "en-GB",
};

const callExport = httpsCallable(functions, "cloudCenterOrderExport", {
  timeout: 300000,
});

export const OrderTable: React.FC = () => {
  /* Hooks and Const*/
  const { t, i18n } = useTranslation();

  const lng = i18n.language;

  const [fromDate, toDate] = useAppSelector(selectDateRange);

  const { resultSet: cubeResult, isLoading } = useAppQuery<CloudCenterOrder>({
    dimensions: [
      "CloudCenterOrders.id",
      "CloudCenterOrders.createdAtTz",
      "CloudCenterOrders.kitchenAcceptedAtTz",
      "CloudCenterOrders.kitchenReadyAtTz",
      "CloudCenterOrders.frontdeskReadyAtTz",
      "CloudCenterOrders.completedAtTz",
      "CloudCenterOrders.orderType",
      "CloudCenterOrders.name",
      "CloudCenterOrders.status",
      "CloudCenterOrders.platform",
      "Brands.name",
      "BrandGroups.name",
      "Orders.amount",
      "Orders.status",
      "Stores.name",
    ],
    filters: [
      {
        member: "CloudCenterOrders.createdAt",
        operator: "inDateRange",
        values: [fromDate, toDate],
      },
    ],
  });

  /* States */
  const [selectedOrder, setSelectedOrder] = useState<CloudCenterOrder | null>(
    null
  );

  const columns: Column<any>[] = [
    {
      title: t("orderTable.platform"),
      render: (rowData) => (
        <LogoImage
          platform={rowData["CloudCenterOrders.platform"]}
          width={25}
        />
      ),
      width: 100,
    },
    {
      title: t("orderTable.type"),
      render: (rowData) => (
        <OrderStatusIcon orderType={rowData["CloudCenterOrders.orderType"]} />
      ),
      width: 100,
    },
    {
      title: t("orderTable.order"),
      field: "CloudCenterOrders.name",
      width: 220,
    },
    { title: t("orderTable.brand"), field: "Brands.name" },
    { title: t("orderTable.brandGroup"), field: "BrandGroups.name" },
    { title: t("orderTable.storeName"), field: "Stores.name" },
    {
      title: t("orderTable.status"),
      render: (rowData) =>
        t(`orderStatus.${rowData["CloudCenterOrders.status"]}` as any),
    },
    {
      title: t("orderTable.processTime.receivedAt"),
      field: "CloudCenterOrders.createdAtTz",
      type: "datetime",
      dateSetting: { locale: dateFormatMap[lng] },
    },
    {
      title: t("orderTable.processTime.acceptedAt"),
      field: "CloudCenterOrders.kitchenAcceptedAtTz",
      type: "datetime",
      dateSetting: { locale: dateFormatMap[lng] },
    },
    {
      title: t("orderTable.processTime.readyAt"),
      field: "CloudCenterOrders.kitchenReadyAtTz",
      type: "datetime",
      dateSetting: { locale: dateFormatMap[lng] },
    },
    {
      title: t("orderTable.completedAt"),
      defaultSort: "desc",
      field: "CloudCenterOrders.completedAtTz",
      type: "datetime",
      dateSetting: { locale: dateFormatMap[lng] },
    },
    {
      title: t("orderTable.status"),
      field: "Orders.status",
      render: (rowData) => {
        return t(`orderTable.${rowData["Orders.status"]}` as any);
      },
    },
    { title: t("orderTable.total"), field: "Orders.amount", type: "currency" },
  ];

  const data = cubeResult?.tablePivot() as any;

  const [exporting, setIsExporting] = useState(false);

  const cloudCenterId = useAppSelector(selectCloudCenterId);

  const handleExportWithItems = async () => {
    setIsExporting(true);
    try {
      const result = await callExport({
        cloudCenterId,
        fromDate,
        toDate,
      });

      //@ts-ignore
      const file = new File([result.data], "orders.csv", {
        type: "text/csv;charset=utf-8",
      });

      saveAs(file, `orders ${dayjs().format("YYYY MM_DD HH_mm")}.csv`, {
        autoBom: true,
      });
    } finally {
      setIsExporting(false);
    }
  };

  if (isLoading || !cubeResult) {
    return (
      <Box
        flex={1}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <CircularProgress style={{ margin: 70 }} />
      </Box>
    );
  }

  return (
    <>
      <Dialog open={exporting}>
        <CircularProgress style={{ margin: 70 }} />
        <Typography style={{ alignSelf: "center", margin: 10 }}>
          Exporting...
        </Typography>
      </Dialog>
      <MaterialTable
        style={{ margin: 10 }}
        columns={columns}
        data={data}
        title="Orders"
        onRowClick={(_, rowData) => setSelectedOrder(rowData)}
        options={{
          exportAllData: true,
          exportMenu: [
            {
              label: "Export CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "orders"),
            },
            {
              label: "Export with items CSV",
              exportFunc: handleExportWithItems,
            },
          ],
        }}
      />
      {selectedOrder && (
        <OrderDetailsDialog
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}
      {/* <CompletedWebOrders /> */}
    </>
  );
};
