import { Box, Typography, Divider } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import FlexView from "react-flexview/lib";
import useDimensions from "react-cool-dimensions";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CloudCenterOrder, OrderSourceType } from "@queue/types";
import { useTheme } from "@mui/material/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useMemo } from "react";
import { BiCartAlt } from "react-icons/bi";
import { KpiCard } from "../dashboard/kpi/KpiCard";
import { CustomPackagingCard, RegularPackagingCard } from "./PackageCard";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { usePackagingOrders } from "../../hooks/db";

export const PackageDashboard: React.FC = () => {
  /* Hooks */
  const handleFullScreen = useFullScreenHandle();
  const theme = useTheme();
  const { t } = useTranslation();

  const fullScreenToggler = () => {
    if (handleFullScreen.active) {
      handleFullScreen.exit();
    } else {
      handleFullScreen.enter();
    }
  };

  const orders = usePackagingOrders();

  /* Calculate optimal Card Width Margin=Card(10)+Scrollbar(5)*/
  const { observe, width } = useDimensions();

  const [optimalCardSize, setOptimalCardSize] = useState(0);

  useMemo(() => {
    const currentMargin = 20;
    const minimumCardSize = 280;
    const itemCount = Math.floor(width / minimumCardSize);
    setOptimalCardSize(Math.round(width / itemCount - currentMargin));
  }, [width]);

  // const [clearingAll, setClearingAll] = useState(false);

  // const handleClearAll = async () => {
  //   setClearingAll(true);
  //   const batches = _.chunk(kitchenOrders, 500).map((orders) => {
  //     const batch = firebaseApp.firestore().batch();

  //     const ordersRef = firebaseApp
  //       .firestore()
  //       .collection("cloudCenters")
  //       .doc(cloudCenterId)
  //       .collection("deliveryOrders");

  //     orders.forEach((o) => {
  //       batch.update(ordersRef.doc(o.id), {
  //         status: CloudCenterOrderStatus.frontdeskReady,
  //         frontdeskReadyAt: new Date(),
  //       });
  //     });
  //     return batch.commit();
  //   });
  //   await Promise.all(batches);
  //   setClearingAll(false);
  // };

  return (
    <FullScreen handle={handleFullScreen}>
      <Box
        style={{
          height: "100vh",
        }}
        ref={observe}
      >
        {/* FLexView for KPI */}
        <FlexView
          hAlignContent="center"
          style={{ marginLeft: 18, paddingTop: 10, marginBottom: 5 }}
        >
          <FlexView
            vAlignContent="center"
            hAlignContent="left"
            style={{ flex: 1 }}
          >
            <KpiCard
              icon={
                <BiCartAlt
                  style={{ alignSelf: "center", margin: 10, color: "black" }}
                  fontSize={"medium"}
                />
              }
              colour="#f8a01a"
              count={orders?.data?.length || 0}
              label={t("package.ongoing")}
              disabled={true}
            />
          </FlexView>

          <Typography
            variant="h3"
            style={{
              fontWeight: "bolder",
              padding: 5,
              color: theme.palette.primary.main,
              flex: 1,
              alignSelf: "center",
              textAlign: "center",
            }}
            onClick={fullScreenToggler}
          >
            {t("package.header")}
          </Typography>

          {/* Empty Flexviw to keep Header in the middle */}
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          ></div>
        </FlexView>

        <Divider variant="middle" />

        <Scrollbars
          style={{
            height: "90vh",
            margin: 0,
            padding: 0,
          }}
          autoHide
        >
          <FlexView wrap style={{ marginLeft: 15, marginRight: 5 }}>
            {orders?.data?.map((order: any) => {
              if (
                order.platform === OrderSourceType.Custom &&
                order.storeOrderIds
              ) {
                return (
                  <CustomPackagingCard
                    //@ts-ignore
                    order={order}
                    cardWidth={optimalCardSize}
                    key={order.id}
                  />
                );
              } else {
                return (
                  <RegularPackagingCard
                    //@ts-ignore
                    order={order}
                    cardWidth={optimalCardSize}
                    key={order.id}
                  />
                );
              }
            })}
          </FlexView>
        </Scrollbars>
      </Box>
    </FullScreen>
  );
};
